import { Outlet, useNavigate } from 'react-router-dom'

import classes from '../styles.module.css'

const Layout = () => {

    const navigate = useNavigate()
    
    const handleClick = (route) => {
        navigate(`/${route}`)
    }
    return (
        <div>
            <div className={classes.sideBar}>
                <div className={`${classes.SideBarContent} ${classes.userConsentSideBar}`} onClick={() => handleClick('user-consent')}>
                    User Consent
                </div>
                <div className={`${classes.SideBarContent} ${classes.legalDisclaimerSideBar}`} onClick={() => handleClick('legal-disclaimer')}>
                    Legal Disclaimer
                </div>   
            </div>
            <Outlet />
        </div>
    )
}

export default Layout