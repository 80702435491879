import { useState, useEffect } from 'react';
import { Button, Drawer } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom'
import { UnorderedListOutlined } from '@ant-design/icons';

import Bull from '../Images/bullIconWithoutBG.png'
import classes from '../styles.module.css'


const Header = ({children}) => {
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(location.pathname.slice(1) || 'home')
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const navigate = useNavigate()

    // update current page on location pathname changes
    // for eg: pressing back button on browser history
    useEffect(() => {
        setCurrentPage(location.pathname.slice(1) || 'home')
    }, [location.pathname])

    const handleNavigation = (route) => {
       setCurrentPage(route ? route : 'home') 
       navigate(`/${route}`)
    }

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false)
    }

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    }

    const handleSideDrawerNavigation = (page) => {
        handleNavigation(page);
        setIsDrawerOpen(false)
    }

    return (
        <>
            <header className={classes.headerContainer}>
                <div className={classes.leftHeaderContainer}>
                    <img src={Bull} alt="bull-icon" className={classes.logo}/>
                    <h1>Bull Investor</h1>
                </div>
                <div className={classes.rightHeaderContainer}>
                    <Button type="text" size="large" onClick={() => handleNavigation('')}
                        {...(currentPage === 'home' ? {
                            color: "default",
                            variant: "solid"
                          } : null)
                        }
                    >
                        Home
                    </Button>
                    <Button type="text" size="large" onClick={() => handleNavigation('packages')}
                        {...(currentPage === 'packages' ? {
                                color: "default",
                                variant: "solid"
                            } : null)
                        }>
                            Packages
                    </Button>
                    <Button type="text" size="large" onClick={() => handleNavigation('bank-details')}
                        {...(currentPage === 'bank-details' ? {
                                    color: "default",
                                    variant: "solid"
                                } : null)
                        }>
                        Bank Details
                    </Button>
                    <Button type="text" size="large" onClick={() => handleNavigation('contact-us')}
                        {...(currentPage === 'contact-us' ? {
                                color: "default",
                                variant: "solid"
                            } : null)
                        }>
                        Contact Us
                    </Button>
                </div>
                <div className={classes.listIcon}>
                    <UnorderedListOutlined style={{ fontSize: '24px' }} onClick={handleDrawerOpen} />
                </div>
            </header>
             <Drawer title="Navigate To" onClose={handleCloseDrawer} open={isDrawerOpen}>
                <div className={classes.sideNavigationDrawer}>
                    <Button color="default" variant="link" 
                        onClick={() => handleSideDrawerNavigation('')}
                        {...(currentPage === 'home' ? {
                            variant: "filled"
                        } : null)}
                    >
                        Home
                    </Button>
                    <Button color="default" variant="link"
                        onClick={() => handleSideDrawerNavigation('packages')}
                        {...(currentPage === 'packages' ? {
                            variant: "filled"
                        } : null)}
                    >
                        Packages
                    </Button>
                    <Button color="default" variant="link"
                        onClick={() => handleSideDrawerNavigation('bank-details')}
                        {...(currentPage === 'bank-details' ? {
                            variant: "filled"
                        } : null)}
                    >
                        Bank Details
                    </Button>
                    <Button color="default" variant="link"
                        onClick={() => handleSideDrawerNavigation('contact-us')}
                        {...(currentPage === 'contact-us' ? {
                            variant: "filled"
                        } : null)}
                    >
                        Contact Us
                    </Button>
                </div>
            </Drawer>
            {children}
        </>
    )
}

export default Header