import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import AdvertisingDisclaimer from './AdvertisingDisclaimer';
import BankDetails from './BankDetails';
import ContactUs from './ContactUs';
import Footer from './Footer';
import GrievanceRedressalProcess from './GrievanceRedressalProcess';
import Header from './Header';
import LegalDisclaimer from './LegalDisclaimer';
import Packages from './Packages';
import PrivacyPolicy from './PrivacyPolicy';
import RefundPolicy from './RefundPolicy';
import RegisteredResearchAnalyst from './RegisteredResearchAnalyst';
import Layout from './Layout';
import StandardDisclaimer from './StandardDisclaimer';
import TermsAndConditions from './TermsAndConditions';
import UserConsent from './UserConsent';

import 'swiper/css';
import 'swiper/css/navigation';
import DosDonts from './Dos&Donts';
import MailVerification from './MailVerification';
import HomePage from './HomePage';

function App() {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0,0);
    }, [location.pathname])

  return (
        <>
            <Header />
            <div style={{flex: 1, 
                    backgroundImage: 'linear-gradient(skyblue, white)',
                    backgroundSize: '100% 90%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top'
            }}>
            <Routes>
                    <Route path='/' element={<Layout />} >
                        <Route index element={<HomePage />} />
                        <Route path='/packages' element={<Packages/>} />
                        <Route path='/bank-details' element={<BankDetails/>} />
                        <Route path='/contact-us' element={<ContactUs/>} />
                        <Route path='/refund-policy' element={<RefundPolicy/>} />
                        <Route path='/legal-disclaimer' element={<LegalDisclaimer/>} />
                        <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
                        <Route path='/user-consent' element={<UserConsent/>} />
                        <Route path='/advertising-disclaimer' element={<AdvertisingDisclaimer/>} />
                        <Route path='/standard-disclaimer' element={<StandardDisclaimer/>} />
                        <Route path='/grievance-redressal-process' element={<GrievanceRedressalProcess/>} />
                        <Route path='/registered-research-analyst' element={<RegisteredResearchAnalyst/>} />
                        <Route path='/dos-donts' element={<DosDonts/>} />
                        <Route path='/terms-n-conditions' element={<TermsAndConditions/>} />
                        <Route path="/mail-verification" Component={MailVerification} />
                    </Route>
            </Routes>
            </div>
            <Footer />
        </>
  );
}

export default App;
