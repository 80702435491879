import classes from '../styles.module.css'

const DosDonts = () =>{
    return(
        <div className={classes.footerLinksContainer}>
            <p>
                Investing in the share market carries both opportunities and risks. It is essential to understand that past performance does not guarantee future results, and trading involves the risk of losing money.
            </p>
            <p>We suggest a few Do's and Don'ts as a general practice while trading which are mentioned below:-</p>
            <p>1) Only trade with your own capital and not with borrowed/loan money as there is risk of loss.</p>
            <p>2) Never trade with your entire capital in one single trade in order to avoid big losses.</p>
            <p>3) Always Trade/ invest by using stoploss </p>
            <p>4) Always trade with a portion of your capital </p>
            <p>5) Always place the stop loss in your broker's terminal at the time of placing the order , do not keep a mental Stoploss </p>
            <p>6) Do not let emotions like fear, greed, or panic drive your trading decisions.</p>
            <p>7)Only invest funds that you are willing and able to lose.</p>
            <p>8) Avoid trading with leverage as much as possible</p>
            <p>Always remember :- </p>
            <p>We at https://Bullinvestor.com/ will never ask for your demat/trading account login id and password. If someone ask you for the details please deny them and mail us on our official email id -  Compliance.bullinvestor@gmail.com</p>
            <p>Trading in the share market can be both rewarding and risky.  Remember, a successful trading strategy requires patience, planning, and informed decision-making.  We hope that you will follow the Above points while practice in your Trade.</p>
            <p>Thank you.</p>
        </div>
    )
}

export default DosDonts