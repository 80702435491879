import { useRef, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Image, Modal, notification, Spin } from 'antd';
import emailjs from 'emailjs-com';

import { Live_Demo_Email } from '../constants';
import Disclaimer from '../Disclaimer';
import FormContainer from '../FormContainer/index.jsx';
import GoldenRules from '../GoldenRules/index.jsx';
import TradingImg from "../Images/1705072458804.png";
import classes from '../styles.module.css';
import Constants from "./InputFields.json";

const HomePage = ({history}) => {
    const initialData = {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
    }
    
    const formRef = useRef(null)
    const [showDisclaimerModal, setShowDisclaimerModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();

    const handleModalClose = () => {
        setShowDisclaimerModal(false)
    }

    const handleSubmit = async(data) => {
        console.log("process", process.env)
        try {
            setIsLoading(true)
            await emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_LIVE_DEMO_TEMPLATE_ID, {...data, ...{to_email: Live_Demo_Email}}, process.env.REACT_APP_PUBLIC_KEY)
            console.log('Mail Sent Successfully')
            formRef.current.resetFormData()
            api.success({
                message: 'Success',
                description:
                'Your request has been received. Our Team will contact you shortly.',
            });
        } catch(error) {
             api.error({
                message: 'Something went wrong!. Please try again!'
            })
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Spin tip="Loading" size="large" fullscreen spinning={isLoading} />
            {contextHolder}
            {showDisclaimerModal ? 
                (<Modal 
                    title="DISCLAIMER"
                    open 
                    footer={
                    <Button type="primary" onClick={handleModalClose}>
                        Cancel
                    </Button>
                    }
                    onCancel={handleModalClose}>
                        <Disclaimer /> 
                </Modal>)
            : null}
            <div className={classes.disclaimerContainer}>
                <div className={classes.disclaimerSectionContainer}>
                    <InfoCircleOutlined style={{fontSize: '13px', color: 'red'}}/>
                    <span className={classes.disclaimerText}>Investment in the stock market is subject to market risk, we DO NOT offer any guaranteed profit services or Fixed Returns Services.</span>
                    <Button type="link" size="small" onClick={() => setShowDisclaimerModal(true)}>Read More</Button>
                </div> 
            </div>
            <div className={classes.homePageContainer}>
                <Image
                    width={'60%'}
                    src={TradingImg}
                    className={classes.tradingImg}
                    preview={false}
                />
                <FormContainer 
                    inputFields={Constants.InputFields}
                    title="Register for live demo"
                    initialState={initialData}
                    handleSubmit={handleSubmit}
                    ref={formRef}
                />
            </div>
            <GoldenRules />
        </>
    )
}

export default HomePage